const allChannels = [
    'Airbnb',
    'Booking.com',
    'Expedia',
    'HomeAway',
    'TripAdvisor',
    'Vrbo'
];

export default allChannels;
