// countries.js
export const countries = [
    { name: "United States", code: "+1" },
    { name: "United Kingdom", code: "+44" },
    { name: "Canada", code: "+1" },
    { name: "Australia", code: "+61" },
    { name: "Germany", code: "+49" },
    { name: "France", code: "+33" },
    { name: "Italy", code: "+39" },
    { name: "Spain", code: "+34" },
    { name: "India", code: "+91" },
    { name: "China", code: "+86" },
    { name: "Japan", code: "+81" },
    { name: "South Korea", code: "+82" },
    { name: "Brazil", code: "+55" },
    { name: "Mexico", code: "+52" },
    { name: "Russia", code: "+7" },
    { name: "South Africa", code: "+27" },
    { name: "Netherlands", code: "+31" },
    { name: "Switzerland", code: "+41" },
    { name: "Sweden", code: "+46" },
    { name: "Norway", code: "+47" },
    { name: "Denmark", code: "+45" },
    { name: "Finland", code: "+358" },
    { name: "Poland", code: "+48" },
    { name: "Turkey", code: "+90" },
    { name: "Saudi Arabia", code: "+966" },
    { name: "United Arab Emirates", code: "+971" },
    { name: "Singapore", code: "+65" },
    { name: "Malaysia", code: "+60" },
    { name: "Thailand", code: "+66" },
    { name: "Vietnam", code: "+84" },
    { name: "Philippines", code: "+63" },
    { name: "Indonesia", code: "+62" },
    { name: "New Zealand", code: "+64" },
    { name: "Ireland", code: "+353" },
    { name: "Austria", code: "+43" },
    { name: "Belgium", code: "+32" },
    { name: "Portugal", code: "+351" },
    { name: "Greece", code: "+30" },
    { name: "Czech Republic", code: "+420" },
    { name: "Hungary", code: "+36" },
    { name: "Israel", code: "+972" },
    { name: "Egypt", code: "+20" },
    { name: "Nigeria", code: "+234" },
    { name: "Kenya", code: "+254" },
    { name: "Argentina", code: "+54" },
    { name: "Chile", code: "+56" },
    { name: "Colombia", code: "+57" },
    { name: "Peru", code: "+51" },
    { name: "Venezuela", code: "+58" },
    { name: "Pakistan", code: "+92" },
    { name: "Bangladesh", code: "+880" },
    { name: "Sri Lanka", code: "+94" },
    { name: "Nepal", code: "+977" },
    { name: "Afghanistan", code: "+93" },
    { name: "Iraq", code: "+964" },
    { name: "Iran", code: "+98" },
    { name: "Ukraine", code: "+380" },
    { name: "Belarus", code: "+375" },
    { name: "Kazakhstan", code: "+7" },
    { name: "Qatar", code: "+974" },
    { name: "Oman", code: "+968" },
    { name: "Kuwait", code: "+965" },
    { name: "Bahrain", code: "+973" },
    { name: "Costa Rica", code: "+506" },
    { name: "Panama", code: "+507" },
    { name: "Dominican Republic", code: "+1" },
    { name: "Puerto Rico", code: "+1" },
    { name: "Jamaica", code: "+1" },
    { name: "Trinidad and Tobago", code: "+1" },
    { name: "Barbados", code: "+1" },
];
